import VueToasted from 'vue-toasted'

// This custom configuration extends the Vue prototype and injects the vue-toast plugin so we can use it globally in Vue 3
// This function will except the current app instance
// This makes $toasted available inside any component template in the application, and also on 'this' of any component instance

export const injectToasted = (app) => {
  const mockVue = {
    use(plugin, args) {
      plugin.install(this, args)
    },
    component: app.component
  }
  mockVue.prototype = {}
  mockVue.use(VueToasted, { duration: 5000, className: 'nr-toast' })
  Object.assign(app.config.globalProperties, mockVue.prototype)
}
