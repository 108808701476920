export default [
  {
    field: 'id',
    label: 'Request',
    pathName: 'request_path',
    hidden: false,
    sortable: true,
    sticky: true,
    headerClass: 'is-sticky-column',
    cellClass: 'is-sticky-column',
    maxChar: 11,
    width: 85
  },
  {
    field: 'request_state',
    label: 'Status',
    hidden: false,
    sortable: true,
    // These are the default icons, but they may be overriden depending on user permissions.
    icons: {
      Open: ['folder-open', 'is-blue'],
      Overdue: ['exclamation-triangle', 'is-red'],
      'Due soon': ['flag', 'is-yellow'],
      Closed: ['check-square', 'is-black'],
      'Waiting for requester': ['pause', 'is-gray']
    }
  },
  {
    field: 'due_date',
    label: 'Due date',
    hidden: false,
    sortable: true
  },
  {
    field: 'request_date',
    label: 'Request date',
    hidden: false,
    sortable: true
  },
  {
    field: 'requester_name',
    label: 'Requester',
    hidden: false,
    sortable: true,
    maxChar: 20,
    width: 218
  },
  {
    field: 'request_text',
    label: 'Request description',
    hidden: false,
    sortable: false,
    maxChar: 65,
    width: 510
  },
  {
    field: 'department_names',
    label: 'Departments',
    hidden: false,
    sortable: true,
    maxChar: 60,
    width: 457
  },
  {
    field: 'poc_name',
    label: 'Point of contact',
    hidden: false,
    sortable: true
  },
  {
    field: 'visibility',
    label: 'Visibility',
    hidden: false,
    sortable: true
  },
  {
    field: 'staff_cost',
    label: 'Staff cost',
    hidden: false,
    sortable: true
  },
  {
    field: 'expiration_date',
    label: 'Expiration date',
    hidden: false,
    sortable: true
  }
]
